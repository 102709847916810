function compareDataTokenId(a, b) {
    if (a["tokenId"] < b["tokenId"]) {
        return -1;
    } else if (a["tokenId"] > b["tokenId"]) {
        return 1;
    }
    return 0;
}

// function comparePrintTime(a, b) {
//     if (a.printTime < b.printTime) {
//         return -1;
//     } else if (a.printTime > b.printTime) {
//         return 1;
//     }
//     return 0;
// }

function getGen(tokenAttributes) {
    for (var i = 0; i < tokenAttributes.length; i++) {
        if (tokenAttributes[i]["trait_type"] == "Generation") {
            return tokenAttributes[i]["value"];
        }
    }
    throw 'Generation not found';
}

function getJam(tokenAttributes) {
    for (var i = 0; i < tokenAttributes.length; i++) {
        if (tokenAttributes[i]["trait_type"] == "Type") {
            return (tokenAttributes[i]["value"] == "Jam");
        }
    }
    throw 'Generation not found';
}

function getVariant(tokenAttributes) {
    for (var i = 0; i < tokenAttributes.length; i++) {
        if (tokenAttributes[i]["trait_type"] == "Variant") {
            return tokenAttributes[i]["value"];
        }
    }
    return 0;
}

function computeParent(tokenData) {
    var mask = (BigInt(1) << BigInt(16)*BigInt(getGen(tokenData["metadata"]["attributes"])-1)) - BigInt(1);
    return tokenData["tokenId"] & mask;
}

class CreatedPrint {
    constructor(tokenId, name, description, image_url, thumbnail_url, animation_url_webm, animation_url_mp4, generation, jam, variant, owner, print_time, next_print_time, print_pending, simulate) {
        this.tokenId = tokenId;
        this.name = name;
        this.description = description;
        this.imageURL = image_url;
        this.thumbnailURL = thumbnail_url;
        this.animationURLWEBM = animation_url_webm;
        this.animationURLMP4 = animation_url_mp4;
        this.generation = generation;
        this.jam = jam;
        this.variant = variant;
        this.owner = owner;
        if (!jam) {
            this.printsRemaining = [6,5,4,3,2,1,0][generation];
        } else {
            this.printsRemaining = 0;
        }
        this.prints = [];
        this.printTime = simulate ? parseInt((new Date()).getTime() / 1000) : print_time;
        this.nextPrintTime = next_print_time;
        this.printPending = print_pending;
        this.simulate = simulate;
    }

    addPrint(print) {
        // Simulate print time if necessary
        if (this.simulate) {
            var printCount = (this.printsRemaining-[6,5,4,3,2,1,0][this.generation])+1;
            var printTime = this.printTime+2419200*printCount;
            print.printTime = printTime;
            print.printTime = parseInt((new Date()).getTime() / 1000)
        }
        this.prints.push(print);
        this.printsRemaining--;
    }
}

function parseReplicatorData(data, simulate=false) {
    var printOrder = [];
    for (var i = 0; i < data.length; i++) {
        data[i]["tokenId"] = BigInt(data[i]["tokenId"]);
        printOrder.push(data[i]["tokenId"]);
    }


    data.sort(compareDataTokenId);

    var printMap = {};
    var replicatorCount = 0;
    var jamCount = 0;
    var maxGenerationReached = 0;
    var hasPrintsRemaining = false;
    var hasPrintsPending = false;
    for (var i = 0; i < data.length; i++) {
        var jam = getJam(data[i]["metadata"]["attributes"]);
        var variant;
        if (jam) {
            variant = getVariant(data[i]["metadata"]["attributes"]);
            jamCount++;
        } else {
            replicatorCount++;
        }
        var generation = getGen(data[i]["metadata"]["attributes"])-1;
        var metadata = data[i]["metadata"];
        var createdPrint = new CreatedPrint(data[i]["tokenId"], metadata["name"], metadata["description"], metadata["image_url"], metadata["thumbnail_url"], metadata["animation_url_webm"], metadata["animation_url_mp4"], generation, jam, variant, metadata["owner"], metadata["print_time"], metadata["next_print_time"], metadata["print_pending"], simulate);
        maxGenerationReached = Math.max(maxGenerationReached, generation);
        printMap[data[i]["tokenId"]] = createdPrint;
        var parentTokenId = computeParent(data[i]);
        if (parentTokenId != BigInt(0)) {
            printMap[parentTokenId].addPrint(createdPrint);
        }
    }

    var nextPrintTime;
    var allPrints = [];
    if (!simulate) {
        for (var key of printOrder) {
            var value = printMap[key];
            if (value.nextPrintTime) {
                if (!nextPrintTime) {
                    nextPrintTime = value.nextPrintTime;
                } else {
                    nextPrintTime = Math.min(value.nextPrintTime, nextPrintTime);
                }
            }
            allPrints.push(value);
        }
    } else {
        for (var key of printOrder) {
            var value = printMap[key];
            // TODO: Currently just faking printPending to test
            if (value.printsRemaining) {
                value.printPending = Math.random() < 0.25;
            }
            if (value.printPending) {
                hasPrintsPending = true;
            }
            if (value.printsRemaining) {
                hasPrintsRemaining = true;
                // TODO: Currently just faking next print timestamp
                nextPrintTime = Math.round(Date.now()/1000) + 43200;
            }
            allPrints.push(value);
        }
    }

    return [printMap[BigInt(1)], allPrints, replicatorCount, jamCount, maxGenerationReached, hasPrintsPending, hasPrintsRemaining, nextPrintTime];
}

export { parseReplicatorData, CreatedPrint }
