<template>
	<div class="details">
		<div class="media">
			<img :src="selectedNode.imageURL" :class="{'no-video': !(selectedNode.animationURLWEBM || selectedNode.animationURLMP4)}" />
			<template v-if="selectedNode.animationURLWEBM || selectedNode.animationURLMP4">
				<div class="video-container">
					<video
						crossorigin="anonymous"
						playsinline muted autoplay loop preload disablePictureInPicture disableRemotePlayback
						ref="video"
						:class="{ 'show': videoReady }"
					>
						<source v-if="selectedNode.animationURLWEBM" :src="selectedNode.animationURLWEBM" type="video/webm">
						<source v-if="selectedNode.animationURLMP4" :src="selectedNode.animationURLMP4" type="video/mp4">
					</video>
				</div>
				<div class="loading" :class="{ 'show': !videoReady }">
					<template v-if="!videoReady">
						<i></i>
						<i></i>
						<i></i>
						<i></i>
					</template>
					<button class="play" v-else-if="!hidePlayButton" @click="playVideo">&#9654;</button>
				</div>
				<button class="sound" @click="toggleSound" :class="{muted: muted}"></button>
			</template>
		</div>
		<div class="info">
			<div><span>TokenID</span><span>{{selectedNode.tokenId}}</span></div>
			<div><span>Name</span><span>{{selectedNode.name}}</span></div>
			<div><span>Description</span><p>{{selectedNode.description}}</p></div>
			<div><span>Owner</span><span>{{selectedNode.owner}}</span></div>
			<div><span>Generation</span><span>{{selectedNode.generation + 1}}</span></div>
			<div><span>Status</span><span>{{selectedNode.jam ? 'JAM' : (selectedNode.printPending ? 'READY' : (selectedNode.printsRemaining ? 'WAITING' : 'COMPLETE'))}}</span></div>
			<div><span>Variant</span><span>{{selectedNode.variant ? selectedNode.variant : 'N/A'}}</span></div>
			<div><span>Date</span><span>{{printTime(selectedNode.printTime)}}</span></div>
			<div v-if="selectedNode.printsRemaining > 0 && !selectedNode.jam"><span>Prints Remaining</span><span>{{selectedNode.printsRemaining}}</span></div>
			<div><span>OpenSea URL</span><a target="_blank" :href="`https://opensea.io/assets/0xae1fb0cce66904b9fa2b60bef2b8057ce2441538/${selectedNode.tokenId}`">Link</a></div>
		</div>
		<button class="close-details" @click="close">&times;</button>
	</div>
</template>
<script>
export default {
	name: 'Details',
	props: {
		selectedNode: {
			type: Object,
			default: () => {}
		},
		printTime: {
			type: Function,
		}
	},
	data() {
		return {
			videoReady: false,
			hidePlayButton: false,
			muted: true,
		}
	},
	mounted() {
		this.$refs.video.addEventListener('canplaythrough', () => {
			this.videoReady = true
			if (this.muted) {
				this.toggleSound()
			}
		}, false)
		this.$refs.video.addEventListener('play', () => {
			this.hidePlayButton = true
		}, false)
	},
	watch: {
		'selectedNode': {
			handler() {
				this.$refs.video.load()
				this.videoReady = false
			}
		},
		"videoURLsReady": {
			handler() {
				this.$refs.video.addEventListener('canplaythrough', () => {
					this.videoReady = true
					if (this.muted) {
						this.toggleSound()
					}
				}, false)
				this.$refs.video.addEventListener('play', () => {
					this.hidePlayButton = true
				}, false)
			}
		}
	},
	computed: {
		videoURLsReady() {
			return this.selectedNode.animationURLWEBM || this.selectedNode.animationURLMP4
		}
	},
	methods: {
		playVideo() {
			if (this.$refs.video){
				this.$refs.video.play()
			}
		},
		toggleSound() {
			this.muted = !this.muted
			this.$refs.video.muted = !this.$refs.video.muted
		},
		close() {
			this.$emit('close-detail')
		}
	}
}
</script>
<style lang="scss" scoped>
	.details {
		display: flex;
		width: 100%;
		height: 100%;

		.close-details {
			position: absolute;
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;
			border: 1px solid rgba(255,255,255,0.2);
			border-top: 0;
			border-right: 0;
			background: rgba(255,255,255,0.1);
		}
		.media {
			flex-shrink: 0;
			width: calc((100vh - 100px - 4rem) * 0.8);
			max-width: calc(100% - 200px);
			height: 100%;
			margin: 0;
			position: relative;

			img {
				width: 100%;
				max-width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center top;
				filter: blur(20px);

				&.no-video {
					filter: blur(0);
				}
			}
			.video-container {
				position: absolute;
				top: 0;
				left: 0;
				width: auto;
				max-width: 100%;
				height: 100%;

				video {
					opacity: 0;
					width: 100%;
					height: auto;
					transition: opacity 0.25s;
					object-fit: contain;
					object-position: center top;

					&.show {
						opacity: 1;
					}
				}
			}
			.loading {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%,-50%,0);
				z-index: 10000;
				width: 30px;
				height: 30px;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr;
				justify-content: center;
				align-items: center;

				@keyframes blinking {
					from {
						opacity: 0;
					}
					to {
						opacity: 0.5;
					}
				}
				i {
					width: 10px;
					height: 10px;
					border-radius: 25%;
					opacity: 0;
					background-color: white;
				}
				&.show {
					i {
						animation-name: blinking;
						animation-duration: 1s;
						animtion-timing-function: linear ;
						animation-iteration-count: infinite;
						animation-direction: alternate;

						&:nth-child(2) { animation-delay: 0.25s; }
						&:nth-child(3) { animation-delay: 0.5s; }
						&:nth-child(4) { animation-delay: 0.75s; }
					}
				}
			}
			.sound {
				position: absolute;
				top: 15px;
				right: 12px;
				width: 24px;
				height: 24px;
				font-size: 0;
				z-index: 25;
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url('data:image/svg+xml;utf8,<svg width="174" height="139" viewBox="0 0 174 139" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M147.045 138.23C163.759 119.569 173.929 94.9164 173.929 67.883C173.929 42.0231 164.623 18.3414 149.184 0L139.941 9.24333C153.047 25.2026 160.91 45.6266 160.91 67.883C160.91 91.3129 152.196 112.712 137.825 129.009L147.045 138.23Z" fill="white"/><path d="M128.376 119.56C139.765 105.443 146.589 87.4626 146.589 67.883C146.589 49.4834 140.563 32.4959 130.384 18.8L121.061 28.1236C128.943 39.3717 133.57 53.0846 133.57 67.883C133.57 83.8648 128.173 98.5804 119.108 110.293L128.376 119.56Z" fill="white"/><path d="M108.997 100.181C115.453 91.0582 119.249 79.9158 119.249 67.883C119.249 57.0408 116.167 46.9213 110.834 38.3507L101.302 47.8828C104.45 53.8579 106.23 60.6636 106.23 67.883C106.23 76.3057 103.807 84.1653 99.6154 90.7998L108.997 100.181Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M4 41.275C1.79086 41.275 0 43.0658 0 45.275V93.275C0 95.4841 1.79086 97.275 4 97.275H41.1997L73.315 129.39C74.459 130.534 76.1794 130.876 77.6741 130.257C79.1688 129.638 80.1434 128.18 80.1434 126.562V12.275C80.1434 10.6571 79.1688 9.19856 77.6741 8.57944C76.1794 7.96032 74.459 8.30254 73.315 9.44653L41.4865 41.275H4Z" fill="white"/></svg>');
				&.muted {
					background-image: url('data:image/svg+xml;utf8,<svg width="185" height="123" viewBox="0 0 185 123" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M165.577 41.5772L157.092 33.0919L138 52.1838L118.908 33.0919L110.423 41.5772L129.515 60.669L110.423 79.7609L118.908 88.2462L138 69.1543L157.092 88.2462L165.577 79.7609L146.485 60.669L165.577 41.5772Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M4 33.0001C1.79086 33.0001 0 34.791 0 37.0001V85.0001C0 87.2092 1.79086 89.0001 4 89.0001H41.1997L73.315 121.115C74.459 122.259 76.1794 122.602 77.6741 121.982C79.1688 121.363 80.1434 119.905 80.1434 118.287V4.00011C80.1434 2.38226 79.1688 0.923715 77.6741 0.304591C76.1794 -0.314533 74.459 0.0276902 73.315 1.17168L41.4865 33.0001H4Z" fill="white"/></svg>');
				}
			}
		}
		.info {
			background: rgba(0,0,0,0.15);
			display: block;
			flex-direction: column;
			align-items: flex-end;
			padding: 15px;
			border-right: 0;
			border-bottom: 0;
			overflow-y: scroll;
			justify-self: flex-end;
			position: relative;
			width: 100%;
			min-width: 200px;
			transition: opacity 0.25s;

			&::-webkit-scrollbar {
				width: 10px;
				background: rgba(0,0,0,0.1);
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(255,255,255,0.1);
			}
			div {
				width: 100%;
				min-height: min-content;
				height: auto;
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: 12px 1fr;
				align-items: flex-start;
				padding: 9px 0;
				margin: 0;
				border-bottom: 1px solid rgba(255,255,255,0.125);

				&:first-child {
					border-top: 1px solid rgba(255,255,255,0.125);
				}

				* {
					width: 100%;
					min-height: min-content;
					height: 100%;
					display: block;
				}
				span:first-child {
					height: auto;
					font-size: 8px;
					opacity: 0.6;
					font-weight: bold;
					letter-spacing: 0.1px;
					text-transform: uppercase;
				}
				*:last-child {
					letter-spacing: 0.4px;
					font-size: 11px;
					line-height: 1.3;
					margin-top: 1px;
					white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
					white-space: -webkit-pre-wrap;          /* Chrome & Safari */
					white-space: -pre-wrap;                 /* Opera 4-6 */
					white-space: -o-pre-wrap;               /* Opera 7 */
					white-space: pre-wrap;                  /* CSS3 */
					word-wrap: break-word;                  /* Internet Explorer 5.5+ */
					word-break: break-all;
					white-space: normal;
				}
			}
		}
		@media screen and (orientation: portrait) {
			display: block;
			overflow-y: scroll;
			z-index: 10000000;

			&::-webkit-scrollbar {
				width: 0;
				background: rgba(0,0,0,0.1);
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(255,255,255,0.1);
			}
			.media {
				width: 100%;
				max-width: 100%;
				height: calc((100vw - 20px) * 1.25);

				img {
					object-position: center top;
					width: 100%;
					min-height: calc(1.249382716 * (100vw - 20px));
					height: auto;
				}
				.sound {
					top: auto;
					bottom: 15px;
				}
			}
			.info {
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				border-width: 0;
				border-top-width: 1px;
				max-width: none;
				overflow-y: visible;
				padding: 0;
				background: none;
				backdrop-filter: none;
				margin-top: -3px;


				div {
					padding: 10px;
					background: rgba(255,255,255,0.1);
				}
			}
		}
	}
</style>
