<template>
	<nav :class="{show: navReady, hide: isShowingAbout}">
		<a class="main-menu" @click="toggleAbout" :class="{show: showAboutButton, hide: isShowingAbout}"><span>&rarr;</span></a>
		<div class="other-links">
			<a target="_blank" href="https://etherscan.io/address/0xae1fb0cce66904b9fa2b60bef2b8057ce2441538"><img src="@/assets/images/etherscan-logo.svg" />Etherscan</a>
			<a target="_blank" href="https://opensea.io/collection/replicator-by-mad-dog-jones"><img src="@/assets/images/opensea-logo.svg" />Opensea</a>
			<a target="_blank" href="https://www.instagram.com/mad.dog.jones/"><img src="@/assets/images/instagram-logo.png" />Instagram</a>
			<a target="_blank" href="https://twitter.com/Mad_Dog_Jones/"><img src="@/assets/images/twitter-logo.png" />Twitter</a>
			<a target="_blank" href="https://discord.com/invite/nhztRAbN6w"><img src="@/assets/images/discord-logo.svg" />Discord</a>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'Menu',
	data() {
		return {
			navReady: false,
			isShowingAbout: false,
			showAboutButton: false,
		}
	},
	mounted() {
		window.addEventListener('intro-ended', () => {
			this.navReady = true
		}, false)
		window.addEventListener('skip-intro', () => {
			this.navReady = true
		}, false)
		window.addEventListener('cascade-ended', () => {
			this.showAboutButton = true
		}, false)
		window.addEventListener('hide-about', () => {
			this.isShowingAbout = false
			this.showAboutButton = true
		}, false)

		window.addEventListener('show-about', () => {
			this.isShowingAbout = true
			this.showAboutButton = false
		}, false)
},
	methods: {
		toggleAbout() {
			if (this.isShowingAbout) {
				this.isShowingAbout = false
				window.dispatchEvent(new Event('hide-about'))
			} else {
				this.isShowingAbout = true
				window.dispatchEvent(new Event('show-about'))
			}
		}
	}
}
</script>

<style lang="scss" scoped>
nav {
	height: 100%;
	bottom: 0;
	right: 2rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	z-index: 10000;
	transition: opacity 0.25s;
	pointer-events: none;
	overflow: visible;

	&.show {
		pointer-events: initial;

		.other-links {
			opacity: 1;
		}
	}
	a.main-menu {
		position: absolute;
		top: 0;
		right: calc(25vw + 0.5rem);
		transform: translate3d(calc(50% - 10px), -50vh, 100px);
		transition: transform 0.25s, opacity 0.25s;
		letter-spacing: 1px;
		opacity: 0;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
    padding: 0 5px;
    border: 1px solid rgba(255,255,255,0.7);
		border-radius: 50%;
		width: 80px;
		height: 80px;

		span {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			font-family: 'Chivo', sans-serif;
			font-size: 30px;
			letter-spacing: 1px;
			color: white;
			text-transform: uppercase;
			opacity: 0.8;
		}
		&:hover {
			background: white;

			span {
				opacity: 1;
				color: black;
			}
		}
		&:active {

		}
		&.show {
			opacity: 1;
			transform: translate3d(50%, -50vh, 100px);

			span:last-child {
				opacity: 1;
				transform: translateX(0);
			}
		}
		&.hide, &.show.hide {
			opacity: 0;
		}

	}
	.other-links {
		width: min-content;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 15px;
		opacity: 0;
		transition: opacity 0.25s;

		a {
			width: 22px;
			font-size: 0;

			img {
				width: 22px;
				height: auto;
				object-fit: cover;
			}
		}

	}
	@media screen and (orientation: landscape) {
		a.main-menu {
			opacity: 0 !important;
			pointer-events: none !important;
		}
	}
	@media screen and (orientation: portrait) {
		right: 0;
		position: relative;
		flex-direction:	column;
		justify-content: center;
		width: 100vw;

		a.main-menu {
			width: 50px;
			height: 50px;
			top: 22.5%;
			left: 50%;
			transform: translate3d(-50%, calc(-50% + 10px), 0);

			span {
				font-size: 20px;
			}
			&:hover {
				transform: translate3d(-50%, -50%, 0) scale(1.05) !important;

				span {
					color: black;
				}
			}
			&:active {
				background: white;
				transform: translate3d(-50%, -50%, 0) scale(1) !important;
			}
			&.show {
				transform: translate3d(-50%, -50%, 0);
			}
		}
		.other-links {
			grid-gap: 25px;
			margin: 40px auto 0;

			a {
				width: 25px;
			}
		}
		&.hide {
			opacity: 0;
			pointer-events: none;
		}
	}
}
</style>
