<template>
	<div id="app">
		<transition name="fade">
			<Intro v-if="!contentReady" />
		</transition>
		<Content :ready="contentReady" />
	</div>
</template>

<script>
import Intro from '@/components/Intro.vue'
import Content from '@/components/Content.vue'

export default {
	name: 'App',
	components: {
		Intro,
		Content
	},
	data() {
		return {
			contentReady: false,
		}
	},
	mounted() {
		const pathname = new URL(location.href).pathname;

		if (pathname.indexOf('/prints') == 0) {
			setTimeout(() => {
				this.contentReady = true
				window.dispatchEvent(new Event('skip-intro'));
				window.dispatchEvent(new Event('show-about'));
			}, 100)
		}
		else {
			window.addEventListener('intro-ended', ()=> {
				this.contentReady = true
			}, false)
		}
	}
}
</script>

<style lang="scss">
	#app {
		width: 100vw;
		height: 100%;
		position: relative;
		background: url('assets/images/frame1.jpg');
		background-position: center center;
		background-size: cover;
		overflow: hidden;

		.fade-enter-active, .fade-leave-active {
			transition: opacity .5s;
		}
		.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
			opacity: 0;
		}
		.intro, .content {
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
</style>
