<template>
	<div class="simulation">
		<div class="simulation-visual">
			<Simulator :simulation="simulation" :legend="legend" :max-generations="maxGenerations" />
		</div>
		<div class="simulation-legend">
			<div v-for="(key, idx) in legend" :key="idx">
				<span>{{key.label}}</span>
				<i :style="`background: rgb(${key.color});`"></i>
			</div>
			<div><span>PRINTING</span><i style="background: rgb(100,100,100);"></i></div>
		</div>
		<div class="simulation-info" v-if="simulation.length">
			<div class="simulation-data" :class="{show: simulationComplete}">
				<div><span>Replicators:</span><span>{{simulation[2]}}</span></div>
				<div><span>Jams:</span><span>{{simulation[3]}}</span></div>
				<div><span>Total:</span><span>{{simulation[2] + simulation[3]}}</span></div>
				<div><span>Generations:</span><span>{{maxGenerations}}</span></div>
			</div>
			<div class="simulating" :class="{show: !simulationComplete}">
				<em>Rendering</em> <span>.</span><span>.</span><span>.</span>
				<div></div>
			</div>
		</div>
		<div
			class="node-thumbnail"
			:class="{show: nodeThumbnail}"
			:style="`
				top: ${nodeThumbnail ? nodeThumbnail.position[1] : 0}px;
				left: ${nodeThumbnail ? nodeThumbnail.position[0] : 0}px;
				background-image: url('${nodeThumbnail ? nodeThumbnail.node.thumbnailURL : ''}');
			`"
		>
		</div>
	</div>
</template>

<script>
import Simulator from "@/components/Simulator"
export default {
	name: 'SimulatorPanel',
	components: {
		Simulator
	},
	props: {
		simulation: Array,
		maxGenerations: Number,
		legend: {
			type: Object,
			default: () => {},
		}
	},
	data() {
		return {
			interval: undefined,
			simulationComplete: false,
			nodeThumbnail: false,
		}
	},
	mounted() {
		this.resimulate()

		window.addEventListener('simulation-start', () => {
			this.simulationComplete = false
		})
		window.addEventListener('simulation-ended', () => {
			this.simulationComplete = true
		})
		window.addEventListener('node-position', (e) => {
			this.nodeThumbnail = e
		})
		window.addEventListener('hover-node', (e) => {
			if (!e.node) {
				this.nodeThumbnail = false
			}
		})
	},
	beforeDestroy() {
		clearInterval(this.interval)
	},
	methods: {
		resimulate() {
			this.simulationComplete = false
		}
	}
}
</script>

<style lang="scss" scoped>
	.simulation {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-rows: 1fr auto;
		border: 1px solid rgba(255,255,255,0.5);
		position: relative;

		.simulation-legend {
			position: absolute;
			max-width: 150px;
			width: 100%;
			height: auto;
			top: 0;
			left: 0;
			padding: 20px 20px;

			div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0.25rem 0;

				i {
					width: 6px;
					height: 6px;
					border-radius: 50%;
					display: block;
					margin-left: 20px;
				}
				span {
					font-size: 11px;
					font-weight: bold;
				}
			}
		}
		.simulation-info {
			align-self: flex-end;
			display: grid;
			grid-template-columns: 1fr;
			min-height: 80px;
			height: auto;
			position: relative;
			overflow: visible;

			> div {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: 0;
				transition: all 0.25s;
				display: grid;
				justify-content: center;
				align-items: center;

				* {
					color: white;
				}
			}
			> div.show {
				opacity: 1;
				transform: translateY(0);
			}
			.simulation-data {
				transform: translateY(50%);
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr;

				div {
					border-top: 1px solid rgba(255,255,255,0.5);
					display: block;
					position: relative;
					width: 100%;
					height: 100%;

					&:nth-child(1),&:nth-child(3) {
						border-right: 1px solid rgba(255,255,255,0.5);
					}
					span {;
						position: absolute;

						&:first-child { top: 10px; left: 10px; font-size: 10px; }
						&:last-child { bottom: 10px; right: 10px; font-size: 20px; }
					}

					&:nth-child(1) { grid-column: 1 / 2; grid-row: 1 / 2; }
					&:nth-child(2) { grid-column: 2 / 3; grid-row: 1 / 2; }
					&:nth-child(3) { grid-column: 1 / 2; grid-row: 2 / 3; }
					&:nth-child(4) { grid-column: 2 / 3; grid-row: 2 / 3; }

					&:nth-child(3), &:nth-child(4) {
						border-bottom: 0;
					}
					&:nth-child(2), &:nth-child(4) {
						border-right: 0;
					}
				}
			}
			.simulating {
				transform: translateY(-50%);
				display: flex;
				justify-content: center;
				position: relative;

				&.show {
					> div {
						animation: loading 10s ease-in 0s 1 normal;
					}
				}

				@keyframes loading {
					0% {
						transform: translate3d(-100%,0,0);
					}
					100% {
						transform: translate3d(0,0,0);
					}
				}
				div {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: white;
					transform: translate3d(-100%,0,0);
					animation-fill-mode: backwards;
				}

				@keyframes opacity {
					0% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}

				span {
					margin-left: 2px;
					animation: opacity 0.5s linear 0s infinite alternate;

					&:nth-child(1) { animation-delay: 0; }
					&:nth-child(2) { animation-delay: 0.25s; }
					&:nth-child(3) { animation-delay: 0.5s; }
				}
			}
			button, em, span {
				font-family: 'Chivo', monospace;
				font-style: initial;
				text-transform: uppercase;
				font-size: 15px;
			}
		}
		.node-thumbnail {
			opacity: 0;
			position: absolute;
			width: 20px;
			height: 20px;
			transform: translate3d(-50%, -50%, 0);
			background-color: rgba(255,255,255,0.1);
			background-position: center center;
			background-size: cover;
			border-radius: 20px;
			border: 1px solid rgba(255,255,255,0.25);
			transition: width 0.25s, height 0.25s, opacity 0.25s;

			&.show {
				width: 40px;
				height: 40px;
				opacity: 1;
			}
		}
		@media screen and (orientation: portrait) {
			height: min-content;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border-color: rgba(255,255,255,0.05) !important;

			.simulation-visual {
				width: calc(100vw - 2rem - 3px);
				height: calc(100vw - 2rem);
				margin: 0 auto;
			}
			.simulation-data {
				div {
					border-color: rgba(255,255,255,0.05) !important;
				}
			}
			.node-thumbnail {
				display: none;
			}
		}
	}
</style>
