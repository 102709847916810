<template>
	<svg ref="svg" :fill="actualFill" :stroke="stroke" width="391" height="47" viewBox="0 0 391 47" xmlns="http://www.w3.org/2000/svg">
		<path d="M382.6 2.70002C379.8 1.80002 377 1.4 374.1 1.4C369.1 1.3 364 1.4 359 1.4C356.8 1.4 356.8 1.40001 356.3 3.50001C353.2 16.8 350.1 30.1 347.1 43.4C347 44 346.5 44.7 347 45.6C351 45.6 355.1 45.6 359.2 45.6C360.1 45.6 360.4 45.2 360.6 44.4C361 42.5 361.4 40.5 361.9 38.6C362.7 35.8 363.1 32.8 364.1 30C364.7 30.5 364.8 31 365 31.5C366.4 35.6 367.8 39.6 369.3 43.7C370 45.6 370 45.6 372 45.6C376 45.6 380 45.6 384 45.6C384.5 45.6 385.1 45.8 385.5 45.1C382.8 40.1 380 35.1 377.3 30.1C378.1 29.3 378.7 29.4 379.3 29.2C384.1 27.7 387.4 24.6 389.1 19.8C389.8 17.9 390.2 15.9 390.3 13.9C390.5 8.1 387.9 4.50002 382.6 2.70002ZM370.7 21.6C369.8 21.7 369 21.9 368.1 21.8H366.9C366 21.8 365.8 21.4 366 20.6C366.6 18.1 367.2 15.6 367.7 13.2C367.9 12.5 368.1 12 368.9 12C371.3 12 373.8 11.5 376.1 13.7C376.3 13.9 376.4 14.2 376.4 14.5C376.1 18.4 374.4 21.1 370.7 21.6Z" />
		<path d="M43.6 20.1C44.5 17.8 44.9 15.3 44.9 12.8C44.9 7.69999 42.5 4.40001 37.6 2.70001C34.8 1.70001 31.8 1.39999 28.9 1.29999C23.1 1.29999 17.4 1.29999 11.6 1.29999C8.40001 14.8 5.20001 28.3 2.10001 41.9C1.90001 43 1.20001 45.5 1.20001 45.5C5.00001 45.5 9.09999 45.5 12.8 45.5C15 45.5 15 45.5 15.5 43.4C16.4 39.4 17.4 35.3 18.3 31.3C18.4 30.8 18.6 30 18.9 29.5C19 29.8 19.3 30.5 19.4 30.8C20.9 35.1 22.5 39.4 23.9 43.7C24.4 45.1 25 45.6 26.4 45.6C28.9 45.5 31.5 45.6 34.1 45.6H40.4C37.4 40.1 34.6 35 31.7 29.8C32.9 29.4 33.8 29.1 34.7 28.8C39.1 27.2 42 24.3 43.6 20.1ZM30.8 16.8C30.3 18.8 29.3 20.3 27.3 21.1C25.5 21.8 23.6 21.9 21.6 21.9C20.4 21.9 20.7 20.9 21 19.7C21.4 17.9 21.8 16.1 22.3 14.2C22.7 12.6 22.8 12 23.9 12C24.4 12 24.9 12 25.9 12C26.1 12 26.8 12 27.5 12.1C30.6 12.5 31.5 13.9 30.8 16.8Z" />
		<path d="M346.1 13.6C345.6 8.10001 342.9 4.20001 337.6 2.20001C334.5 1.00001 331.2 0.700012 328 0.700012C325.8 0.700012 323.5 0.799988 321.3 1.29999C315.1 2.59999 310.1 5.70001 306.9 11.1C302.6 18.3 300.8 26.2 301.6 34.8C302 39 304.6 42.6 308.5 44.2C314.1 46.4 319.8 46.8 325.5 45.8C332.3 44.7 337.8 41.4 341.2 35.2C344.9 28.6 346.8 21.3 346.1 13.6ZM328.5 32C326.8 34.7 323.9 35.5 320.7 35.4C320.2 35.4 319.7 35.4 319.3 35.4C316.1 35.1 314.3 33.3 314.5 30C314.9 25 316 20.2 318.5 15.8C320.9 11.4 326.7 10.8 330.3 12.2C332 12.9 333.1 14.3 333 16.1C333 21.8 331.6 27.2 328.5 32Z" />
		<path d="M266.1 44.1C265.4 37.7 264.8 31.4 264.1 25.1C263.3 17.7 262.5 10.4 261.8 3C261.7 1.8 261.2 1.39999 260 1.39999C255.8 1.39999 251.5 1.39999 247.3 1.39999C246.3 1.39999 245.6 1.70001 245.2 2.60001C245 3.10001 244.6 3.6 244.3 4C237.8 15 231.4 26 224.9 37.1C223.3 39.8 221.7 42.6 220 45.5H266.1C266.1 44.8 266.1 44.5 266.1 44.1ZM250 28.6C247.9 28.6 245.9 28.6 243.2 28.6C245.9 23.4 248.3 18.6 250.8 13.9C251.7 14.3 251.4 14.9 251.4 15.4C251.6 19.1 251.5 22.9 251.9 26.6C252.1 28.6 252 28.6 250 28.6Z" />
		<path d="M220.2 20C218 20 215.8 20 213.5 20C211.6 20 211.6 19.9 212 17.8C212.1 17.3 212.1 16.8 212.1 16.3C211.6 12.1 209.2 10.6 204 12C202.4 12.4 201.1 13.4 200.1 14.7C196.7 19.6 195.3 25.2 195.6 31.1C195.8 34.8 198.4 35.9 201.4 35.7C204.1 35.5 206.2 34.2 207.6 31.8C208.3 30.6 208.9 29.2 209.5 27.9C209.8 27.1 210.3 26.8 211.1 26.8C214.8 26.8 218.4 26.8 222.1 26.8C223 26.8 223.6 27 223.2 28C220.9 35.2 217.5 41.6 209.9 44.6C205.7 46.2 201.4 46.6 197 46.2C195.4 46 193.8 45.8 192.3 45.3C186.1 43.4 182.3 38.8 182 32.3C181.6 24.5 183.6 17.2 187.8 10.7C191.6 4.60001 197.4 1.49999 204.5 0.899991C208.6 0.499991 212.8 0.600003 216.8 2.1C225 5.1 226.4 13.3 225.2 19.2C225 20.2 224.3 20.1 223.6 20.1C222.5 20 221.3 20 220.2 20Z" />
		<path d="M87.4 1.5C86.7 4.6 86 7.49999 85.4 10.4C85.1 11.7 84.5 12 83.2 12C78.2 11.9 73.3 12 68.3 12C64.9 12 65.5 11.6 64.6 15C64.3 16 64.1 17.3 63.9 18.3C64.9 18.3 65.9 18.3 66.7 18.3C71 18.3 75.3 18.3 79.7 18.3C81.3 18.3 81.4 18.5 81.1 20C80.6 22.1 80.1 24.2 79.6 26.3C79.3 27.6 78.7 27.9 77.1 27.9C73.9 28 70.7 27.8 67.6 27.8C61.5 27.8 61.7 27.1 61 30.4C60.7 31.8 60.4 33.1 60.1 34.6L60 35C60.8 35 61.7 35 62.4 35C67.4 35 72.5 35 77.5 35C79.4 35 79.4 35.1 79 36.9C78.5 39.3 77.8 41.7 77.3 44.1C77.1 45.2 76.6 45.6 75.5 45.5C75.1 45.5 74.7 45.5 74.3 45.5C65 45.5 55.7 45.5 46.5 45.5C45.7 45.5 44.3 45.6 43.7 45.5C43.9 44.8 44.1 43.9 44.3 43.2C47.4 29.8 50.6 16.5 53.7 3.10001C54.2 1.20001 54.1 1.20001 55.9 1.20001C66 1.30001 76 1.20001 86.1 1.20001C86.5 1.30001 86.8 1.4 87.4 1.5Z" />
		<path d="M119.4 2.79999C116.4 1.79999 113.3 1.39999 110.2 1.39999C105.3 1.39999 100.5 1.39999 95.6 1.39999C93.3 1.39999 93.4 1.40001 92.8 3.70001C90.7 12.5 88.7 21.4 86.6 30.2C85.4 35.2 84.3 40.1 83.1 45.5C87.6 45.5 91.7 45.5 95.8 45.5C96.8 45.5 96.9 44.8 97.1 44.1C97.9 40.3 98.8 36.6 99.6 32.8C103.9 32.9 108.2 32.8 112.5 31.9C117.7 30.8 122 28.3 124.3 23.1C125.5 20.3 126.2 17.3 126.6 14.3C127.3 8.69999 124.8 4.59999 119.4 2.79999ZM112.8 16.2C112.3 19.7 109.6 22.2 106 22.4C104.8 22.5 103.7 22.4 102.3 22.4C102.8 18.6 103.9 15.3 104.6 11.9C106.6 12.1 108.6 11.6 110.5 12.3C112.3 13 113.1 14.3 112.8 16.2Z" />
		<path d="M273.2 45.5C273.9 42.6 274.3 40.1 274.9 37.6C276.6 30.1 278.5 22.5 280.2 15C281 11.7 281 12.1 277.8 12C275.4 12 273 12.2 270.4 11.8C271.2 8.19999 272.1 4.79999 272.9 1.39999C273.6 1.39999 274.2 1.39999 274.9 1.39999C285 1.39999 295.2 1.39999 305.3 1.39999C305.9 1.39999 306.4 1.30001 307.1 1.70001C306.4 4.70001 305.7 7.79999 305 10.9C304.8 11.8 304.3 12 303.5 12C301.3 12 299 12 296.8 12C294.5 12 294.5 11.4 293.8 14.3C291.5 23.9 289.3 33.4 287.1 43C287 43.5 286.8 44.1 286.7 44.6C286.6 45.3 286.1 45.5 285.5 45.5C281.4 45.5 277.4 45.5 273.2 45.5Z" />
		<path d="M155.6 35.3C154.9 38.6 154.2 41.4 153.5 44.3C152.9 45.8 154 45.5 137.1 45.6C125.3 45.6 126.1 45.8 126.1 45.3C126.4 42.6 127.2 39.9 127.8 37.3C130.3 26.3 132.9 15.2 135.4 4.20002C135.5 3.80002 135.7 3.4 135.7 3C135.8 1.7 136.5 1.3 137.8 1.4C141.7 1.5 145.6 1.4 149.8 1.4C148.9 5.3 148 8.90001 147.2 12.6C145.6 19.4 144 26.3 142.4 33.1C142.3 33.4 142.3 33.7 142.2 34C142 34.9 142.4 35.3 143.3 35.3C145.9 35.3 148.6 35.3 151.2 35.3C152.6 35.3 153.9 35.3 155.6 35.3Z" />
		<path d="M181.7 1.29999C180.8 5.19999 180 8.79999 179.2 12.3C176.8 22.6 174.4 33 172 43.3C171.5 45.4 171.5 45.4 169.3 45.4C165.6 45.4 161.4 45.5 157.7 45.5C158 44.5 158.1 43.8 158.3 43C161.6 29.1 164.8 15.2 168.1 1.29999C172.5 1.29999 176.8 1.29999 181.7 1.29999Z"/>
	</svg>
</template>

<script>
export default {
	name: 'Outline',
	props: {
		fill: String,
		stroke: String,
		idx: Number,
		length: Number,
	},
	computed: {
		actualFill() {
			return window.innerWidth <= window.innerHeight ? 'none' : this.fill
		}
	},
	mounted() {
		this.$refs.svg.addEventListener('transitionend', () => {
			if (this.idx == this.length) {
				window.dispatchEvent(new Event('cascade-ended'))
			}
		})
	}
}
</script>

<style lang="scss" scoped>
	svg {
		width: 100%;
		height: auto;
		object-position: center;
		object-fit: cover;
		stroke-width: 0.5;

		@media screen and (orientation: portrait) {
			stroke-width: 0.33;
		}
	}
</style>
