<template>
	<div class="content" :class="{show: ready, 'show-about': isShowingAbout}" ref="container">
		<Cascade key="cascade" :ready="!isShowingAbout" />
		<div class="logo-menu">
			<img src="@/assets/images/mad-dog-jones-logo.svg" />
			<Menu />
		</div>
		<About :about-showing="isShowingAbout" @close-about="isShowingAbout = false" />
	</div>
</template>

<script>
import ResizeObserverPolyfill from 'resize-observer-polyfill';
const ResizeObs = window.ResizeObserver ? ResizeObserver : ResizeObserverPolyfill
import Cascade from '@/components/Cascade.vue'
import About from '@/components/About.vue'
import Menu from '@/components/Menu.vue'

export default {
	name: 'Content',
	components: {
		Cascade,
		Menu,
		About
	},
	props: {
		ready: {
			type: Boolean,
		}
	},
	data() {
		return {
			isShowingAbout: false,
			resizeObserver: new ResizeObs(() => {
				this.onResize()
			})
		}
	},
	mounted() {
		this.resizeObserver.observe(this.$el)
		this.onResize()

		window.addEventListener('show-about', () => {
			this.isShowingAbout = true
		}, false)
		window.addEventListener('hide-about', () => {
			this.isShowingAbout = false
		}, false)
	},
	watch: {
		'isShowingAbout': {
			handler(e) {
				if (e) {
					let pathname = new URL(location.href).pathname;
					if (pathname.indexOf('/prints/') != 0){
						window.history.pushState("", "", '/prints')
					}
				} else {
					window.history.pushState("", "", "/")
				}
			}
		},
	},
	methods: {
		onResize() {
			if (window.innerWidth >= window.innerHeight) {
				window.addEventListener('intro-ended', () => {
					this.isShowingAbout = true
				}, false)
				window.addEventListener('skip-intro', () => {
					this.isShowingAbout = true
				}, false)
			}
		}
	}
}
</script>

<style lang="scss">
	.content {
		width: 100vw;
		height: 100%;
		display: grid;
		grid-template-rows: auto 100px;
		grid-template-columns: 1fr;
		overflow: hidden;
		opacity: 0;
		justify-content: flex-end;
		transition: all 0.33s;
		will-change: width, transform;
		z-index: 10000;
		pointer-events: none;

		&.show {
			backdrop-filter: saturate(60%) blur(50px);
			opacity: 1;
			pointer-events: initial;
		}
		&.show-about {
			background: rgba(20,20,20,0.6);

			.cascade {
				transform: translate3d(-100%, 0, 0);
			}
			.about {
				transform: translate3d(0, 0, 0);
				opacity: 1;
				pointer-events: initial;
			}
		}
		.cascade, .logo-menu, .about {
			transition: opacity 0.25s;
		}
		.cascade {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		.logo-menu {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			width: 100vw;
			position: relative;
			transition: all 0.25s;
			pointer-events: none;
			will-change: transform;
			display: grid;
			grid-template-columns: 1fr 1fr;
			padding: 0.25rem	2rem 0;
			overflow: visible;

			img {
				flex-shrink: 1;
				margin-right: auto;
				width: 100%;
				max-width: 320px;
				height: auto;
				opacity: 1;
			}
		}
		.about {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.33s, transform 0.5s;
			transform: translate3d(10px,0,0);
		}
		@media screen and (orientation: portrait) {
			grid-template-rows: 1fr;
			grid-template-columns: 1fr;

			&.show-about {
				.logo-menu {
					opacity: 0;
				}
			}
			.cascade {
				height: 60%;
			}
			.logo-menu {
				grid-column: none;
				grid-row: none;
				width: 100vw;
				height: 100%;
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: 60% 40%;
				position: absolute;
				top: 0;
				justify-content: center;
				align-items: center;

				img {
					width: 80%;
					margin: 0 auto;
					transform: translateX(2%);
				}
			}
		}
	}
</style>
