export function printTime(time) {
	try {
		return (new Date(time * 1000)).toISOString()
	}
	catch (e) {
		console.error(time, e)
		return
	}
}

export default {
	printTime
}
