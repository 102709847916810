<template>
	<div class="cascade" :class="{show: showCascade}">
		<Outline v-for="({fill, stroke}, idx) in svgFill"
			:key="idx"
			:idx="idx"
			:length="svgFill.length - 1"
			:fill="fill"
			:stroke="`rgb(${stroke}, ${0.25})`"
		/>
	</div>
</template>

<script>
import ResizeObserverPolyfill from 'resize-observer-polyfill';
const ResizeObs = window.ResizeObserver ? ResizeObserver : ResizeObserverPolyfill
import Outline from '@/components/Outline.vue'

export default {
	name: 'Cascade',
	components: {
		Outline
	},
	props: {
		ready: Boolean,
	},
	data() {
		return {
			showCascade: false,
			padding: 20,
			svgRatio: 8.3200599644,
			svgFill: [],
			resizeObserver: new ResizeObs(() => {
				this.onResize()
			})
		}
	},
	mounted() {
		this.resizeObserver.observe(this.$el)
		this.onResize()

		window.addEventListener('intro-ended', () => {
			setTimeout(() => {
				this.showCascade = true
			}, 1000)
		}, false)
		window.addEventListener('hide-about', () => {
			setTimeout(() => {
				this.showCascade = true
			}, 1000)
		}, false)
		window.addEventListener('show-about', () => {
			this.showCascade = false
		})
	},
	methods: {
		onResize() {
			let padding = this.padding
			let color = '41,43,38'
			color = '255,255,255'
			padding = 5
			if (window.innerWidth <= window.innerHeight){
				color = '255,255,255'
				padding = 5
			}
			let rect = this.$el.getBoundingClientRect()
			let svgWidth = (rect.width - (padding * 2))
			let svgHeight = svgWidth / this.svgRatio
			let containerHeight = (rect.height - (padding * 2))
			let svgCount = Math.floor(containerHeight / svgHeight)
			// let svgRatio = 1 / (svgCount - 1)
			this.svgFill = (Array.from({length: svgCount}, () => { return {fill:`rgba(0,0,0,0)`, stroke: color } })).reverse()
		}
	}
}
</script>

<style lang="scss" scoped>
	.cascade {
		width: 50vw;
		height: 100%;
		padding: 2rem;
		line-height: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		svg {
			opacity: 0;
			transition: opacity 0.25s;

			@function delay($key) {
				@return #{0.1 * $key}s
			}
			@for $i from 1 through 12 {
				&:nth-child(#{$i}) {
					transition-delay: delay($i);
				}
			}
		}
		&.show {
			svg {
				opacity: 1;
			}
		}
		@media screen and (orientation: portrait) {
			width: 100vw;
			padding: 5px;
			opacity: 1;
		}
	}
</style>
