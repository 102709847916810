<template>
	<div ref="container" class="simulator-container"></div>
</template>
<script>
import LegendData from "@/components/LegendData"
import ResizeObserverPolyfill from 'resize-observer-polyfill';
const ResizeObs = window.ResizeObserver ? ResizeObserver : ResizeObserverPolyfill

export default {
	name: 'Simulator',
	props: {
		simulation: Array,
		maxGenerations: Number,
	},
	data() {
		return {
			show: false,
			hoverNode: '',
			canvas: undefined,
			ctx: undefined,
			relWidth: 0,
			relHeight: 0,
			centerXY: [0,0],
			interval: undefined,
			nodes: [],
			scalar: 50,
			currentGenerationNodes: [],
			currentGeneration: 0,
			resizeObserver: new ResizeObs(() => {
				this.onResize()
			}),
			legend: {},
			finishedReplicating: false,
			blinkOn: false,
		}
	},
	mounted() {
		this.legend = LegendData
		this.resizeObserver.observe(this.$el)
		this.onResize()

		window.addEventListener('hide-about', () => {
			this.stopReplication()
		})
		window.addEventListener('hover-node', (e) => {
			this.hoverNode = e.node
		})
		window.addEventListener('about-is-showing', () => {
			this.show = true
			this.init(this.simulation[0])
		})
	},
	watch: {
		simulation: {
			handler(e) {
				this.init(e[0])
			}
		},
		centerXY: {
			handler() {
				this.init(this.simulation[0])
			}
		}
	},
	computed: {
		printReady() {
			return (new Date(this.simulation[7] * 1000)).getTime() - (new Date()).getTime() > 0
		},
		colors() {
			return {
				JAM: {
					node: `rgba(${this.legend.red.color},1)`,
					edge: `rgba(${this.legend.red.color},0.25)`
				},
				COMPLETE: {
					node: `rgba(${this.legend.blue.color},1)`,
					edge: `rgba(${this.legend.blue.color},0.25)`
				},
				WAITING: {
					node: `rgba(${this.legend.orange.color},1)`,
					edge: `rgba(${this.legend.orange.color},0.25)`
				},
				READY: {
					node: `rgba(${this.legend.green.color},1)`,
					edge: `rgba(${this.legend.green.color},0.25)`
				},
				TBP: { // TO BE PRINTED
					node: `rgba(100,100,100,1)`,
					edge: `rgba(255,255,255,0.33)`
				}
			}
		},
		genesisStatus() {
			return this.getNodeStatus(this.simulation[0])
		}
	},
	methods: {
		onResize(){
			this.canvas = this.Canvas(this.$refs.container)
			if (this.canvas) {
				this.ctx = this.canvas.getContext('2d')
			}
		},
		init(root) {
			if (this.show && root) {
				window.dispatchEvent(new Event('simulation-start'))
				if (this.ctx) {
					this.ctx.clearRect(0, 0, this.ctx.width, this.ctx.height);
				}
				this.scalar = this.relWidth >= this.relHeight ? this.relHeight / 16 : this.relWidth / 16
				this.nodes = []
				this.currentGenerationNodes = []
				this.currentGeneration = 0
				let genesisNode = [this.centerXY[0],this.centerXY[1],0,0]
				genesisNode.prints = root.prints
				genesisNode.status = this.getNodeStatus(root)
				this.currentGenerationNodes = [genesisNode]
				this.startReplication()
			}
		},
		Canvas(elem) {
			if (elem) {
				elem.innerHTML = ''
				var canvas = document.createElement('canvas');
				elem.appendChild(canvas);
				var width = elem.offsetWidth;
				var height = elem.offsetHeight;
				this.relWidth = width
				this.relHeight = height
				this.centerXY = [width / 2, height / 2]

				this.setCanvasSize(canvas, width, height)
				return canvas;
			}
		},
		setCanvasSize(canvas, width, height) {
			canvas.style.width = width * 2 + "px";
			canvas.style.height = height * 2 + "px";

			canvas.setAttribute('width', width * 2 + "px");
			canvas.setAttribute('height', height * 2 + "px");
			var context = canvas.getContext("2d");

			context.width = width;
			context.height = height;

			var devicePixelRatio = window.devicePixelRatio || 1;
			var backingStoreRatio = context.webkitBackingStorePixelRatio || context.mozBackingStorePixelRatio || context.msBackingStorePixelRatio || context.oBackingStorePixelRatio || context.backingStorePixelRatio || 1;
			var ratio = devicePixelRatio / backingStoreRatio;

			if (devicePixelRatio !== backingStoreRatio) {
				var oldWidth = canvas.width;
				var oldHeight = canvas.height;

				canvas.width = oldWidth * ratio;
				canvas.height = oldHeight * ratio;

				canvas.style.width = oldWidth + 'px';
				canvas.style.height = oldHeight + 'px';

				context.scale(ratio, ratio);
			}
		},
		factorial(num, stop = 0) {
			let retval = 1

			while (num >= stop) {
				retval *= num
				num--
			}

			return retval
		},
		getVector(node, origin) {
			if (node[0] == origin[0] && node[1] == origin[1]) {
				return [1,0]
			}
			return [
				node[0] - origin[0],
				origin[1] - node[1]
			]
		},
		degreesToRadians(degrees) {
			return degrees * Math.PI / 180
		},
		rotateVector(deg, vector) {
			let radians = this.degreesToRadians(deg)
			let retval = [
				(Math.cos(radians) * vector[0] - Math.sin(radians) * vector[1]),
				(Math.sin(radians) * vector[0] + Math.cos(radians) * vector[1])
			]
			return retval
		},
		getNodeStatus(node) {
			if (node.preStatus) {
				return node.preStatus
			}
			return node.jam ? 'JAM' :
				(node.printPending ? 'READY' :
					(node.printsRemaining > 0 ? 'WAITING' : 'COMPLETE' )
				)
		},
		getNodeParams(parentNode, child, generation, siblingsCount, childNum) {
			let parentVector = this.getVector(parentNode, this.centerXY)
			let	maxNodeCountForGeneration = this.factorial(6, 6 - generation+1)

			let basisAngle
			if (generation > 0) {
				basisAngle = 360 / (maxNodeCountForGeneration + 15)
			} else {
				basisAngle = 60
			}

			let degree = 0

			let flipper = true
			if (siblingsCount % 2 != 0) {
				for (var i = 1; i <= childNum; i++) {
					if (flipper) {
						degree -= basisAngle * (i + 1)
					} else {
						degree += basisAngle * (i + 1)
					}
					flipper = !flipper
				}
			} else {
				for (var o = 0; o < childNum; o++) {
					if (flipper) {
						degree -= basisAngle * (o + 1)
					} else {
						degree += basisAngle * (o + 1)
					}
					flipper = !flipper
				}
			}

			let parentVectorMagnitude = Math.hypot(parentVector[0], parentVector[1])
			let unitParentVector = [parentVector[0] / parentVectorMagnitude, parentVector[1] / parentVectorMagnitude]
			let newVector = this.rotateVector(degree, unitParentVector)
			let x = parentNode[0] + (newVector[0] * (this.scalar))
			let	y = parentNode[1] - (newVector[1] * (this.scalar))
			let node = [x, y, parentNode[0], parentNode[1]]
			node.tokenId = child.tokenId ? child.tokenId : ''
			node.prints = child.prints
			node.degree = degree
			node.generation = this.currentGeneration
			node.status = this.getNodeStatus(child)
			return node
		},
		addGeneration() {
			let newGenerationNodes = []
			let currentGenerationNodes = this.currentGenerationNodes

			if (this.currentGeneration >= 1) {
				currentGenerationNodes.forEach((node) => {
					let nodePrintsLength = node.prints.length + ((node.status == 'READY' || node.status == 'WAITING') ? 1 : 0)
					for (var i = 0; i < nodePrintsLength; i++) {
						let child
						if (node.prints[i]) {
							child = node.prints[i]
						} else {
							child = {
								prints: [],
								preStatus: node.status == 'READY' ? 'ON_READY' : 'ON_WAITING'
							}
						}
						newGenerationNodes.push(this.getNodeParams(node, child, this.currentGeneration - 1, nodePrintsLength, i))
					}
				})
				this.currentGenerationNodes = newGenerationNodes
			}

			return newGenerationNodes
		},
		drawReplicatingGraph() {
			let nodes = [...this.nodes]
			let gen = this.addGeneration()
			nodes = nodes.concat(gen)
			this.draw(nodes)
			this.nodes = nodes
		},
		draw(nodes) {
			let ctx = this.ctx
			ctx.clearRect(0, 0, ctx.width, ctx.height);

			// Draw every node and edge
			var radius = 5.0;

			// Edge
			nodes.forEach((node) => {
				ctx.strokeStyle = this.colors[node.status] ? this.colors[node.status].edge : this.colors['TBP'].edge
				if (node.status == 'ON_READY' || node.status == 'ON_WAITING') {
					ctx.setLineDash([1,2]);
				} else {
					ctx.setLineDash([]);
				}
				ctx.lineWidth = 1;
				ctx.beginPath();
				ctx.moveTo((node[0]), (node[1]));
				ctx.lineTo((node[2]), (node[3]));
				ctx.stroke();
			})

			// Node
			nodes.forEach((node) => {
				ctx.fillStyle = this.colors[node.status] ? this.colors[node.status].node : this.colors['TBP'].node

				if (this.finishedReplicating && this.hoverNode.tokenId && node.tokenId == this.hoverNode.tokenId) {
					ctx.fillStyle = 'rgba(255,255,255,1)'
					let event = new Event('node-position')
					event.position = [node[0], node[1]]
					event.node = this.hoverNode
					window.dispatchEvent(event)
				}
				if (node.status == 'ON_READY' || node.status == 'ON_WAITING') {
					if (node.status == 'ON_WAITING') {
						ctx.fillStyle = 'rgba(255,255,255,0)'
					}
					else if (this.blinkOn) {
						ctx.fillStyle = 'rgba(255,255,255,0)'
					}
				}

				ctx.beginPath();
				ctx.arc(node[0], node[1], radius / node.generation, 0, 2 * Math.PI, false);
				ctx.fill();
			});

			// Genesis node
			ctx.fillStyle = this.colors[this.genesisStatus].node
			if (this.finishedReplicating && this.hoverNode.tokenId == 1n) {
				ctx.fillStyle = 'rgba(255,255,255,1)'
				let event = new Event('node-position')
				event.position = [this.centerXY[0], this.centerXY[1]]
				event.node = this.hoverNode
				window.dispatchEvent(event)
			}
			ctx.beginPath();
			ctx.arc(this.centerXY[0], this.centerXY[1], radius, 0, 2 * Math.PI, false);
			ctx.fill();
		},
		startReplication() {
			this.finishedReplicating = false

			if (this.interval) {
				clearInterval(this.interval)
			}
			this.interval = setInterval(() => {
				if (this.currentGeneration > this.maxGenerations + 1) {
					this.stopReplication()
					this.startFinishedState()
				} else {
					this.drawReplicatingGraph()
					this.currentGeneration++
				}
			}, 1000)
		},
		stopReplication() {
			clearInterval(this.interval)
			window.dispatchEvent(new Event('simulation-ended'))
			return
		},
		startFinishedState() {
			this.finishedReplicating = true
			window.requestAnimationFrame(this.loop)
		},
		loop() {
			this.blinkOn = !this.blinkOn
			this.draw(this.nodes)
			setTimeout(() => {
				window.requestAnimationFrame(this.loop)
			}, 500)
		}
	}
}
</script>
<style lang="scss" scoped>
.simulator-container {
	width: 100%;
	height: 100%;
}
</style>
