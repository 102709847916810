<template>
	<div class="intro">
		<video
			crossorigin="anonymous"
			playsinline muted autoplay preload disablePictureInPicture disableRemotePlayback
			ref="video"
			:class="{ 'show': embedVideo && showVideo }"
		>
			<template v-if="isPortrait">
				<source type="video/webm" src="@/assets/videos/intro-portrait.webm">
				<source type="video/mp4" src="@/assets/videos/intro-portrait.mp4">
			</template>
			<template v-else>
				<source type="video/webm" src="@/assets/videos/intro-landscape.webm">
				<source type="video/mp4" src="@/assets/videos/intro-landscape.mp4">
			</template>
		</video>
		<div class="video-cover" :class="{hide: hidePlayButton}">
			<img src="@/assets/images/frame1.jpg" />
			<div></div>
			<button class="play-button" @click="play" :class="{play: buttonText != 'LOADING'}" v-html="buttonText"></button>
		</div>
		<button class="skip" @click="end" v-if="hidePlayButton && !hideSkipButton"> Skip</button>
	</div>
</template>

<script>
export default {
	name: 'Intro',
	data() {
		return {
			embedVideo: true,
			showVideo: false,
			hidePlayButton: false,
			hideSkipButton: false,
			isPortrait: false,
			buttonText: 'LOADING'
		}
	},
	mounted() {
		this.isPortrait = window.innerWidth < window.innerHeight
		this.$refs.video.addEventListener('canplaythrough', () => {
			this.showVideo = true
		}, false)
		setTimeout(() => {
			this.buttonText = '<span class="play-icon">PLAY</span>'
		}, 2000)
		this.$refs.video.addEventListener('ended', () => {
			this.end()
		}, false)
		this.$refs.video.addEventListener('play', () => {
			this.hidePlayButton = true
		}, false)

		window.addEventListener('skip-intro', ()=> {
			this.hideSkipButton = true
			this.embedVideo = false
		},)
	},
	methods: {
		play() {
			if (this.$refs.video){
				this.$refs.video.play()
			}
		},
		end() {
			this.hideSkipButton = true
			window.dispatchEvent(new Event('intro-ended'))

			setTimeout(() => {
				this.embedVideo = false
			}, 1000)
		}
	}
}
</script>

<style lang="scss" scoped>
	.intro {
		width: 100vw;
		height: 100%;
		display: grid;
		justify-content: center;
		align-items: center;
		box-shadow: inset 0 0  25px rgba(0,0,0,0.2);

		video {
			z-index: 0;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%,-50%,0);
			width: 100vw;
			height: 100vh;
			object-position: center;
			object-fit: cover;
			opacity: 0;
			transition: opacity 0.5s;

			&.show {
				opacity: 1;
			}
		}
		.video-cover {
			opacity: 1;
			z-index: 5;
			width: 100vw;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				position: absolute;
				z-index: 0;
				width: 100vw;
				height: 100%;
				object-fit: cover;
			}
			> div {
				width: 100vw;
				height: 100%;
				background: #2D2D27;
				position: absolute;
				top: 0;
				opacity: 0.4;
				backdrop-filter: saturate(180%) blur(10px);
				z-index: 10;
				transition: all 0.25s;
			}
			&.hide {
				opacity: 0;
				pointer-events: none;
			}
			button.play-button {
				font-size: 12px;
				font-family: 'Chivo', sans-serif;
				color: white;
				letter-spacing: 1px;
				background-color: rgba(255,255,255,0);
				background-image: url('data:image/svg+xml;utf8,<svg width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="64.5" cy="64.5" r="64" stroke="white" stroke-opacity="0.5"/><circle cx="64.5" cy="64.5" r="58.5" stroke="white" stroke-opacity="0.25"/></svg>');
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				width: 80px;
				height: 80px;
				border-radius: 50%;
				z-index: 20;
				opacity: 0.75;
				transition: box-shadow 0.25s, background-color 0.25s;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					background-color: rgba(255,255,255,0.05);
					box-shadow: 0 0 25px 0px rgba(255,255,255, 0.1);
				}
				&:active {
					background-color: rgba(255,255,255,0.8);
					box-shadow: 0 0 25px 0px rgba(255,255,255, 0.2);
				}
			}
		}
		button.skip {
			font-family: 'Chivo', sans-serif;
			text-transform: uppercase;
			font-size: 10px;
			color: white;
			position: absolute;
			bottom: 120px;
			margin-bottom: 1rem;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1000;
			border: 1px solid rgba(255,255,255,0.5);
			letter-spacing: 1px;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			transition: background-color 0.25s;

			&:hover {
				background-color: white;
				color: black;
			}
		}
	}
</style>
