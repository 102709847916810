<template>
	<div class="about" :class="{show: aboutShowing}" ref="about">
		<button @click="closeAbout" :class="{hide: selectedNode}">&times;</button>
		<div class="about-simulation-container" :class="{hide: selectedNode}">
			<SimulatorPanel :simulation="simulation" :legend="legend" :max-generations="maxGenerations" />
		</div>
		<div class="about-content" :class="{hide: selectedNode}">
			<div class="about-content-countdown">
				<h2>Print Log</h2>
				<span>{{countdown}}</span>
			</div>
			<div class="about-content-filter">
				<div>
					<label>Generation</label>
					<select id="generation" dir="rtl" @change="filterChange">
						<option>All</option>
						<option v-for="generation in generationOptions" :key="generation" :value="generation">{{generation + 1}}</option>
					</select>
				</div>
				<div>
					<label>Date</label>
					<select id="date" dir="rtl" @change="filterChange">
						<option>All</option>
						<option v-for="date in dateOptions" :key="date" :value="date">{{ (new Date(date * 1000)).toISOString().split('T')[0] }}</option>
					</select>
				</div>
				<div>
					<label>Type</label>
					<select id="type" dir="rtl" @change="filterChange">
						<option>All</option>
						<option v-for="type in typeOptions" :key="type" :value="type">{{type}}</option>
					</select>
				</div>
			</div>
			<table>
				<thead class="grid-size">
					<th>Print ID</th>
					<th>Name</th>
					<th>Date</th>
					<th>Status</th>
					<th>Print</th>
				</thead>
				<tbody @mouseleave="hoverNode()">
					<tr v-for="(node, idx) in filteredData"
						:key="idx"
						@mouseover="hoverNode(node)"
						@mouseleave="hoverNode()"
						@click="selectNode(node)"
						class="grid-size"
						:class="{selected: node.tokenId == selectedNode.tokenId}"
					>
						<td>{{node.tokenId}}</td>
						<td>{{node.name}}</td>
						<td>{{printTime(node.printTime)}}</td>
						<td>
							<i :style="`background: rgb(${
								node.jam ? legend.red.color :
									(node.printPending ? legend.green.color :
										(node.printsRemaining > 0 ? legend.orange.color : legend.blue.color)
									)
							});`">
							</i>
						</td>
						<td>&#9654;</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="about-details" :class="{show: selectedNode}" :style="`transform: translateY(${detailsTop}px);`">
			<Details v-if="selectedNode" :selected-node="selectedNode" :print-time="printTime" @close-detail="selectedNode = ''" />
		</div>
	</div>
</template>

<script>
import LegendData from "@/components/LegendData"
import simulateData from "@/assets/scripts/simulateData.json"
import {printTime} from "@/components/Utils"
import { parseReplicatorData as simulate } from "@/assets/scripts/parseReplicatorData"
import SimulatorPanel from "@/components/SimulatorPanel"
import Details from "@/components/Details"
export default {
	name: 'About',
	components: {
		SimulatorPanel,
		Details
	},
	props: {
		aboutShowing: Boolean,
	},
	data() {
		return {
			simulation: [],
			selectedNode: '',
			data: [],
			legend: {},
			detailsTop: 0,
			countdown: '',
			filters: {
				generation: undefined,
				date: undefined,
				type: undefined,
			},
		}
	},
	async mounted() {
		this.printTime = printTime
		this.legend = LegendData
		
		let temp_data = simulateData.map(i => {
			let maybeGeneration = i.metadata.description.split('Chapter ')[1]
			let [generation, isJam] = maybeGeneration.split(' - ')

			return {
				...i,
				tokenId:i.tokenId.toString(),
				generation: generation,
				isJam: isJam,
			}
		})
		this.simulation = simulate(temp_data, false)
		this.data = this.simulation[1]

		window.requestAnimationFrame(this.setCountdown)

		this.$el.addEventListener('scroll', (e) => {
			this.detailsTop = e.target.scrollTop
		})

		let tokenId
		let pathname = new URL(location.href).pathname;

		if (pathname.indexOf('/prints/') == 0){
			tokenId = pathname.split('/prints/')[1]
			if (tokenId) {
				let node = this.filteredData.filter(x => x.tokenId == tokenId)
				if (node.length) {
					this.selectedNode = node[0]
				}
			}
		}
	},
	watch: {
		'selectedNode': {
			handler(e) {
				if (e) {
					window.history.pushState("", "", `/prints/${e.tokenId}`)
					this.$refs.about.style.overflowY = 'hidden'
				} else {
					window.history.pushState("", "", `/prints`)
					this.$refs.about.style.overflowY = 'scroll'
				}
			}
		},
		'aboutShowing': {
			handler(e) {
				if (e) {
					window.dispatchEvent(new Event('about-is-showing'))
				}
			}
		}
	},
	computed: {
		paramMap() {
			if (this.simulation[1]){
				return {
					generations: this.simulation[1].map(x => x.generation),
					dates: this.simulation[1].map(x => x.printTime),
					types: this.simulation[1].map(node => this.getNodeStatus(node)),
				}
			} else {
				return {
					generations: [],
				}
			}
		},
		generationOptions() {
			return Array.from(new Set(this.paramMap.generations)).sort()
		},
		maxGenerations() {
			if (this.generationOptions) {
				return Math.max(...this.generationOptions) + 1
			} else {
				return 0
			}
		},
		dateOptions() {
			return Array.from(new Set(this.paramMap.dates)).sort()
		},
		typeOptions() {
			return Array.from(new Set(this.paramMap.types)).sort()
		},
		filteredData() {
			let data = this.data

			data = data.filter(x => {
				let generationFiltered = false, dateFiltered = false, typeFiltered = false

				if (this.filters.generation != undefined) {
					generationFiltered = x.generation == this.filters.generation
				} else {
					generationFiltered = true
				}
				if (this.filters.date != undefined) {
					dateFiltered = x.printTime == this.filters.date
				} else {
					dateFiltered = true
				}
				if (this.filters.type != undefined) {
					typeFiltered = this.getNodeStatus(x) == this.filters.type
				} else {
					typeFiltered = true
				}

				return (generationFiltered && dateFiltered && typeFiltered)
			})

			return data
		}
	},
	methods: {
		setCountdown() {
			this.countdown = this.getNextPrintTime()
			window.requestAnimationFrame(this.setCountdown)
		},
		closeAbout() {
			window.dispatchEvent(new Event('hide-about'))
		},
		getNodeStatus(node) {
			return node.jam ? 'JAM' :
				(node.printPending ? 'READY' :
					(node.printsRemaining > 0 ? 'WAITING' : 'COMPLETE' )
				)
		},
		filterChange(e) {
			if (e.target.value == 'All') {
				this.filters[e.target.id] = undefined
				return
			}
			this.filters[e.target.id] = (e.target.id == 'generation' || e.target.id == 'date') ? parseInt(e.target.value) : e.target.value
		},
		getNextPrintTime() {
			if (!this.simulation[7]) {
				return 'COMPLETE'
			}
			let now = new Date()
			let nextPrint = new Date(this.simulation[7] * 1000)
			var diff = nextPrint.getTime() - now.getTime();
			let countdown = {
				days: '',
				hours: '',
				mins: '',
				seconds: '',
			}

			if (diff > 0) {
				countdown.days = Math.floor(diff / (1000 * 60 * 60 * 24));
				diff -=  countdown.days * (1000 * 60 * 60 * 24);

				countdown.hours = Math.floor(diff / (1000 * 60 * 60));
				diff -= countdown.hours * (1000 * 60 * 60);

				countdown.mins = Math.floor(diff / (1000 * 60));
				diff -= countdown.mins * (1000 * 60);

				countdown.seconds = Math.floor(diff / (1000));
				diff -= countdown.seconds * (1000);

				for (var e in countdown) {
					if (countdown[e] < 10) {
						countdown[e] = `0${countdown[e]}`
					}
				}

				return `NEXT PRINT - ${countdown.days}:${countdown.hours}:${countdown.mins}:${countdown.seconds}`
			} else {
				return 'PRINT READY'
			}
		},
		back() {
			window.dispatchEvent(new Event('hide-about'))
		},
		hoverNode(node) {
			let event = new Event('hover-node')
			event.node = node ? node : ''
			window.dispatchEvent(event)
		},
		selectNode(node) {
			if (this.selectedNode == node) {
				this.selectedNode = ''
			} else {
				this.selectedNode = node
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	.about {
		width: 100%;
		height: 100%;
		line-height: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		position: relative;
		padding: 2rem;
		overflow: hidden;

		> button {
			position: absolute;
			top: 0;
			right: 0;
			width: calc(2rem + 1px);
			height: calc(2rem + 1px);
			border: 1px solid rgba(255,255,255,0.25);
			border-right: 0;
			border-top: 0;
			transition: all 0.25s;
			padding: 0;
			z-index: 10000;
			opacity: 0;
			pointer-events: none;

			&.hide {
				opacity: 0;
			}
			&:hover {
				background: rgba(255,255,255,0.1);
			}
		}
		.about-simulation-container, .about-content, .about-details {
			transition: opacity 0.25s;
		}
		.about-simulation-container {
			&.hide {
				opacity: 0;
			}
		}
		.about-content {
			font-size: 18px;
			display: flex;
			align-items: center;
			display: grid;
			grid-template-rows: 50px 46px 1fr;
			width: 100%;
			height: 100%;
			max-height: 100%;
			border: 1px solid rgba(255,255,255,0.5);
			border-left: 0;
			position: relative;
			background: rgba(0,0,0,0.15);

			.about-content-countdown {
				border-bottom: 1px solid rgba(255,255,255,0.1);
				width: 100%;
				height: 100%;
				z-index: 1000;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 17px 12px;

				h2 {
					font-weight: bold;
					color: white;
					width: auto;
					display: flex;
					justify-content: space-between;
					font-size: 15px;
					text-transform: uppercase;
				}
				div, span {
					display: flex;
					flex-direction: row;
					font-size: 12px;
					font-weight: bold;
					color: white;
					width: auto;
					margin-left: 10px;
				}
			}
			.about-content-filter {
				border-bottom: 1px solid rgba(255,255,255,0.1);
				width: 100%;
				height: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;

				div {
					position: relative;

					* {
						font-size: 11px;
						text-transform: uppercase;
					}
					label {
						position: absolute;
						top: 50%;
						left: 12px;
						transform: translateY(-50%);
					}
					select {
						font-size: 10px;
						font-weight: bold;
						width: 100%;
						height: 100%;
						background: none;
						background-color: none !important;
						background-image: url('data:image/svg+xml;utf8,<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M15.5711 0.571045L8 8.14211L0.42894 0.571045" stroke="white" stroke-width="2"/></svg>');
						background-repeat: no-repeat;
						background-size: 7px;
						background-position: calc(100% - 12px) calc(50%);
						border-right: 1px solid rgba(255,255,255,0.075);
						padding: 12px 30px 12px 100px;
						transition: all 0.25s;

						&:hover {
							background-color: rgba(255,255,255,0.05);
						}
						&:last-child {
							border-right:0;
						}
						option {
							background: none !important;
						}
					}
					&:nth-child(1) {
						label {
							font-size: 0;

							&:before {
								display: block;
								content: 'Generation';
								font-size: 11px;

								@media screen and (max-width: 450px) {
									content: 'Gen.';
								}
							}
						}
					}
				}
			}
			table {
				margin-right: 0;
				width: 100%;
				height: 100%;
				max-height: 100%;
				display: grid;
				grid-template-rows: 38px 1fr;

				.grid-size {
					grid-template-columns: 120px auto 200px 60px;

					> th:nth-child(4){
						padding-right: 10px;
					}
					> td:nth-child(4){
						padding-right: 20px;
					}
					> *:last-child {
						display: none;
					}
				}
				thead {
					display: grid;
					grid-template-rows: 1fr;
					align-items: center;
					position: sticky;
					top: 0;
					border-bottom: 1px solid rgba(255,255,255,0.1);
					box-shadow: 0 3px 6px -2px rgba(0,0,0,0.1);
					padding-right: 10px;

					th {
						text-align: left;
						font-size: 10px;
						text-transform: uppercase;
						padding: 12px;
						width: 100%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;

						&:nth-child(4), &:nth-child(5) {
							padding-left: 0;
							padding-right: 0;
							justify-content: center;
							text-align: center !important;
						}
						&:nth-child(5) {
							padding-right: 20px;
						}
					}
				}
				tbody {
					overflow-y: scroll;

					&::-webkit-scrollbar {
						width: 10px;
						background: rgba(0,0,0,0.1);
					}
					&::-webkit-scrollbar-thumb {
						background-color: rgba(255,255,255,0.1);
					}

					tr {
						display: grid;
						grid-template-rows: 1fr;
						transition: opacity 0.25s;
						align-items: center;
						border-bottom: 1px solid rgba(255,255,255,0.05);
						line-height: 1.35;
						width: 100%;
						position: relative;
						overflow: visible;
						transition: background 0.25s;
						cursor: pointer;

						&.selected, &:hover {
							border-top: 1px solid rgba(255,255,255,0.025);
							margin-top: -1px;
							background: rgba(255,255,255,0.075);
							backdrop-filter: blur(50px);
						}
						td {
							width: 100%;
							height: auto;
							position: relative;
							font-size: 13px;
							padding: 12px;
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;

							&:nth-child(4), &:nth-child(5) {
								justify-content: center;
								text-align: center !important;
							}
							&:nth-child(5) {
								padding-right: 20px;
							}
							i {
								display: block;
								margin: 0 auto;
								width: 6px;
								height: 6px;
								border-radius: 50%;
							}
						}
					}
				}
			}
		}
		.about-details {
			width: calc(50% - 2rem);
			height: calc(100% - 4rem);
			position: absolute;
			top: 2rem;
			left: 2rem;
			opacity: 0;
			border: 1px solid rgba(255,255,255,0.5);
			pointer-events: none;
			z-index: 1000000;


			@media screen and (orientation: landscape) {
				&.show {
					&:hover {
						.details {
							opacity: 0.25;

							&:hover {
								opacity: 1;
							}
						}
					}
				}
			}
			&.show {
				opacity: 1;
				pointer-events: initial;
			}
		}
		@media screen and (orientation: portrait) {
			position: fixed;
			z-index: 1000;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 0;
			width: 100vw;
			max-width: 100vw !important;
			height: 100%;
			grid-template-columns: 1fr;
			grid-template-rows: auto 100%;
			overflow-y: scroll;
			box-shadow: 0 -10px 10px -5px rgba(0,0,0,0.2);
			transform: translate3d(0,100%,0);
			transition: transform 0.33s;

			> button {
				width: 50px;
				height: 50px;
				opacity: 1;
				pointer-events: initial;
			}
			&.show {
				transform: translate3d(0,0,0);
			}
			.about-simulation-container {
				flex-shrink: 0;
				display: grid;
				width: 100%;
				height: min-content;
				margin: 0 auto;
				grid-template-rows: auto auto;
			}
			.about-details {
				position: fixed;
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				top: 10px;
				left: 10px;
				border: 1px solid rgba(255,255,255,0.2);
			}
			.about-content {
				flex-shrink: 0;
				height: 100%;
				max-height: 100%;
				overflow: visible;
				border: 0;
				border-top: 1px solid rgba(255,255,255,0.05);
				margin-top: 2px;

				&.hide {
					opacity: 0;
				}
				.about-content-filter {
					div select {
						padding-left: 40px;

						&:nth-child(1) { padding-left: 80px; }
					}
				}
				.grid-size {
					grid-template-columns: auto 200px 60px !important;
				}
				table {
					thead {
						th {
							&:nth-child(2), &:nth-child(5) {
								display: none;
							}
						}
					}
					tbody {
						tr {
							display: grid;
							grid-template-rows: 1fr;
							padding: 6px 0;

							td {
								padding: 4px 0 4px 12px;

								&:nth-child(2), &:nth-child(5) {
									display: none;
								}
								&:nth-child(1), &:nth-child(3) {
									position: relative;
									font-size: 13px;
								}
								&:nth-child(1) {
									grid-column: 1 / 2;
								}
								&:nth-child(4) {
									grid-column: 3 / 4;
									padding-left: 0;
								}
								&:nth-child(3) {
									grid-column: 2 / 3;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
